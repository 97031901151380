import { useEffect, useState } from "react";

export default function GroupNameInput({ groupId, turnName }) {
  const [value, setValue] = useState("");

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setValue(await localStorage.getItem(turnName));
  };

  const onChange = async (e) => {
    setValue(e.target.value);
    await localStorage.setItem(turnName, e.target.value);
  };

  return (
    <div
      className="groupName"
      style={{
        display: "flex",
        alignItems: "center",
        gap: 5,
      }}
    >
      {/* <p style={{ padding: 10, fontWeight: 500 }}>{turnName}:</p> */}
      <input
        style={{
          // width: "calc(6ch + 45px)",

          padding: "10px 20px",
          fontSize: "2rem",
          fontWeight: 500,
          backgroundColor: "transparent",
        }}
        // type="number"
        // maxlength="6"
        value={value}
        onChange={onChange}
        placeholder="Name"
      />
    </div>
  );
}
