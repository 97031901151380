import React from "react";

export default function CurrentTime({ currentDate }) {
  // const [currentDate, setCurrentDate] = useState("");

  // useEffect(() => {
  //   setInterval(() => {
  //     var hours = new Date().getHours(); //Current Hours
  //     var minutes = new Date().getMinutes(); //Current Minutes
  //     var seconds = new Date().getSeconds(); //Current Seconds
  //     setCurrentDate(
  //       (hours < 10 ? "0" + hours : hours) +
  //         ":" +
  //         (minutes < 10 ? "0" + minutes : minutes) +
  //         ":" +
  //         (seconds < 10 ? "0" + seconds : seconds)
  //     );
  //   }, 1000);
  // }, []);

  return (
    <>
      <h1>{currentDate}</h1>
    </>
  );
}
