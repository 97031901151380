import GroupNameInput from "../../components/GroupNameInput";

import { useEffect, useState } from "react";

import "./GroupMode.css";
import { useCountdown } from "../../libs/useCountdown";
import CurrentTime from "../../libs/CurrentTime";
import logo from "../../logo/icon.svg";

export default function GroupMode({ mode, setMode, currentDate }) {
  const [duration, setDuration] = useState(20);
  const addDuration = (value) => {
    if (value > 120) {
      setDuration(120);
    } else if (value <= 1) {
      setDuration(1);
    } else {
      setDuration(value);
    }
  };

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const [turn1Left, setTurn1Left] = useState();
  const [turn2Left, setTurn2Left] = useState();
  const [turn3Left, setTurn3Left] = useState();
  const [turn4Left, setTurn4Left] = useState();

  const [turn1Right, setTurn1Right] = useState();
  const [turn2Right, setTurn2Right] = useState();
  const [turn3Right, setTurn3Right] = useState();
  const [turn4Right, setTurn4Right] = useState();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getData = async () => {
    setTurn1Left(JSON.parse(await localStorage.getItem("turn1Left")));
    setTurn2Left(JSON.parse(await localStorage.getItem("turn2Left")));
    setTurn3Left(JSON.parse(await localStorage.getItem("turn3Left")));
    setTurn4Left(JSON.parse(await localStorage.getItem("turn4Left")));

    setTurn1Right(JSON.parse(await localStorage.getItem("turn1Right")));
    setTurn2Right(JSON.parse(await localStorage.getItem("turn2Right")));
    setTurn3Right(JSON.parse(await localStorage.getItem("turn3Right")));
    setTurn4Right(JSON.parse(await localStorage.getItem("turn4Right")));

    setDuration(JSON.parse(await localStorage.getItem("duration")));
  };

  const [leftCountdowns, setLeftCountdowns] = useState([]);
  const [rightCountdowns, setRightCountdowns] = useState([]);

  useEffect(() => {
    let data = [];

    if (turn1Left) {
      data?.push(turn1Left);
    }
    if (turn2Left) {
      data?.push(turn2Left);
    }
    if (turn3Left) {
      data?.push(turn3Left);
    }
    if (turn4Left) {
      data?.push(turn4Left);
    }

    let refactoredData = [];

    const timer = setInterval(() => {
      data?.map((item) => {
        let deference = new Date(item?.unix).getTime() - new Date().getTime();

        if (deference <= duration * 60000 * -1) {
          item["show"] = false;
          upsert(item, refactoredData);
        } else {
          item["show"] = true;
          upsert(item, refactoredData);
        }
      });

      let dataToSave = [];

      refactoredData?.map((i) => {
        if (i?.show === true) {
          dataToSave.push(i);
        }
      });
      setLeftCountdowns(dataToSave);
    }, 1000);
    return () => clearTimeout(timer);
  }, [turn1Left, turn2Left, turn3Left, turn4Left, duration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let data = [];

    if (turn1Right) {
      data?.push(turn1Right);
    }
    if (turn2Right) {
      data?.push(turn2Right);
    }
    if (turn3Right) {
      data?.push(turn3Right);
    }
    if (turn4Right) {
      data?.push(turn4Right);
    }

    let refactoredData = [];

    const timer = setInterval(() => {
      data?.map((item) => {
        let deference = new Date(item?.unix).getTime() - new Date().getTime();

        if (deference <= duration * 60000 * -1) {
          item["show"] = false;
          upsert(item, refactoredData);
        } else {
          item["show"] = true;
          upsert(item, refactoredData);
        }
      });

      let dataToSave = [];

      refactoredData?.map((i) => {
        if (i?.show === true) {
          dataToSave.push(i);
        }
      });
      setRightCountdowns(dataToSave);
    }, 1000);
    return () => clearTimeout(timer);
  }, [turn1Right, turn2Right, turn3Right, turn4Right, duration]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="groupMode">
      <section className="topSection">
        <div className="left">
          <div>
            <input
              type="radio"
              id="html"
              name="fav_language"
              value="group"
              checked={mode === "group"}
              onChange={(e) => e.target.checked && setMode("group")}
            />
            {"  "}
            <label
              style={{ fontWeight: 500, fontSize: "1rem" }}
              htmlFor="group"
              checked={mode === "group"}
            >
              Group Mode
            </label>
            <br />
            <input
              style={{ marginTop: 10 }}
              type="radio"
              id="css"
              name="fav_language"
              value="openPitlane"
              checked={mode === "openPitlane"}
              onChange={(e) => e.target.checked && setMode("openPitlane")}
            />
            {"  "}
            <label
              style={{ fontWeight: 500, fontSize: "1rem", marginTop: -5 }}
              htmlFor="openPitlane"
              checked={mode === "openPitlane"}
            >
              Open Pitlane
            </label>
          </div>

          <div className="turns">
            <div className="turn">
              <p>Turn 1:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn1Left?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 1,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn1Left(data);

                  await localStorage.setItem("turn1Left", JSON.stringify(data));
                }}
              />
            </div>

            <div className="turn">
              <p>Turn 2:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn2Left?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 2,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn2Left(data);
                  await localStorage.setItem("turn2Left", JSON.stringify(data));
                }}
              />
            </div>

            <div className="turn">
              <p>Turn 3:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn3Left?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 3,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn3Left(data);
                  await localStorage.setItem("turn3Left", JSON.stringify(data));
                }}
              />
            </div>

            <div className="turn">
              <p>Turn 4:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn4Left?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 4,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn4Left(data);
                  await localStorage.setItem("turn4Left", JSON.stringify(data));
                }}
              />
            </div>
          </div>
        </div>

        <div className="center" style={{ flexDirection: "column", gap: 40 }}>
          <img src={logo} alt="" style={{ width: "100%", height: "5vw" }} />
          <CurrentTime currentDate={currentDate} />
        </div>

        <div className="right">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
            }}
          >
            <p>Turn Duration:</p>
            <input
              style={{ width: 50, padding: "5px 10px" }}
              type="number"
              min={1}
              max={120}
              value={duration}
              onChange={async (e) => {
                addDuration(e.target.value);

                await localStorage.setItem(
                  "duration",
                  JSON.stringify(e.target.value)
                );
              }}
            />
            <p>Min</p>
          </div>

          <div className="turns">
            <div className="turn">
              <p>Turn 1:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn1Right?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 1,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn1Right(data);

                  await localStorage.setItem(
                    "turn1Right",
                    JSON.stringify(data)
                  );
                }}
              />
            </div>

            <div className="turn">
              <p>Turn 2:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn2Right?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 2,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn2Right(data);
                  await localStorage.setItem(
                    "turn2Right",
                    JSON.stringify(data)
                  );
                }}
              />
            </div>

            <div className="turn">
              <p>Turn 3:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn3Right?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 3,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn3Right(data);
                  await localStorage.setItem(
                    "turn3Right",
                    JSON.stringify(data)
                  );
                }}
              />
            </div>

            <div className="turn">
              <p>Turn 4:</p>
              <input
                style={{ padding: "5px 10px" }}
                type="time"
                required
                value={turn4Right?.value}
                onChange={async (e) => {
                  const data = {
                    turn: 4,
                    value: e.target.value,
                    unix: `${date} ${e.target.value}:00`,
                  };
                  setTurn4Right(data);
                  await localStorage.setItem(
                    "turn4Right",
                    JSON.stringify(data)
                  );
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bottomSection">
        {leftCountdowns?.length > 0 ? (
          leftCountdowns?.map((t, index) => {
            return (
              index <= 0 && (
                <CountdownTimer
                  key={index}
                  item={t}
                  date={t?.unix}
                  groupId="group1Name"
                  turnName={t?.turn}
                />
              )
            );
          })
        ) : (
          <AddTurn
            msg={
              turn1Left || turn2Left || turn3Left || turn4Left
                ? "Match Over"
                : "Add Match"
            }
          />
        )}

        {rightCountdowns?.length > 0 ? (
          rightCountdowns?.map((t, index) => {
            return (
              index <= 0 && (
                <CountdownTimer
                  key={index}
                  item={t}
                  date={t?.unix}
                  groupId="group1Name"
                  turnName={t?.turn}
                />
              )
            );
          })
        ) : (
          <AddTurn
            msg={
              turn1Right || turn2Right || turn3Right || turn4Right
                ? "Match Over"
                : "Add Match"
            }
          />
        )}
      </section>
    </div>
  );
}

const upsert = (element, array, setArray) => {
  const i = array.findIndex((_element) => _element.turn === element.turn);
  if (i > -1) {
    array[i] = element;
  } else {
    console.log("NO MATCH", i, array);
    array.push(element);
  }
};

const CountdownTimer = ({ item, date, duration }) => {
  const [days, hours, minutes, seconds] = useCountdown(date);

  return (
    <section
      className={`countdownTimer ${
        days + hours === 0 && minutes < 10 ? "colorChnage" : ""
      } ${days + hours + minutes + seconds <= 0 ? "colorGreen" : ""}`}
    >
      <GroupNameInput turnName={`Turn: ${item?.turn}`} />

      {days + hours + minutes + seconds <= 0 ? (
        <h1>RACE</h1>
      ) : (
        <h1>
          {hours === 0 ? "" : (hours < 10 ? "0" + hours : hours) + ":"}
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </h1>
      )}

      {days + hours === 0 && minutes < 10 ? (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "5vw",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 style={{ fontSize: "3vw", color: "#FFF" }}>
            REFUEL - START ENGINE
          </h2>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

const AddTurn = ({ msg }) => {
  return (
    <section className="countdownTimer">
      <h1>{msg || "Add Turn"}</h1>
    </section>
  );
};
