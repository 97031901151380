import "./App.css";
import { useEffect, useState } from "react";
import GroupMode from "./modes/groupMode/GroupMode";
import OpenPitlaneMode from "./modes/openPitlaneMode/OpenPitlaneMode";

export default function App() {
  const [mode, setMode] = useState("group");

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    setInterval(() => {
      var hours = new Date().getHours(); //Current Hours
      var minutes = new Date().getMinutes(); //Current Minutes
      var seconds = new Date().getSeconds(); //Current Seconds
      setCurrentDate(
        (hours < 10 ? "0" + hours : hours) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds)
      );
    }, 1000);
  }, []);

  return (
    <div className="app">
      {mode === "group" ? (
        <GroupMode mode={mode} setMode={setMode} currentDate={currentDate} />
      ) : (
        <OpenPitlaneMode
          mode={mode}
          setMode={setMode}
          currentDate={currentDate}
        />
      )}
    </div>
  );
}
