import React, { useEffect, useState } from "react";
import CurrentTime from "../../libs/CurrentTime";
import { useCountdown } from "../../libs/useCountdown";
import logo from "../../logo/icon.svg";

export default function OpenPitlaneMode({ mode, setMode, currentDate }) {
  const [pitlaneOpen1, setPitlaneOpen1] = useState();
  const [pitlaneClose1, setPitlaneClose1] = useState();

  const [pitlaneOpen2, setPitlaneOpen2] = useState();
  const [pitlaneClose2, setPitlaneClose2] = useState();

  const [pitlaneOpen3, setPitlaneOpen3] = useState();
  const [pitlaneClose3, setPitlaneClose3] = useState();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setPitlaneOpen1(JSON.parse(await localStorage.getItem("pitlaneOpen1")));
    setPitlaneClose1(JSON.parse(await localStorage.getItem("pitlaneClose1")));
    setPitlaneOpen2(JSON.parse(await localStorage.getItem("pitlaneOpen2")));
    setPitlaneClose2(JSON.parse(await localStorage.getItem("pitlaneClose2")));
    setPitlaneOpen3(JSON.parse(await localStorage.getItem("pitlaneOpen3")));
    setPitlaneClose3(JSON.parse(await localStorage.getItem("pitlaneClose3")));
  };

  const [countdowns, setCountdowns] = useState([]);

  useEffect(() => {
    let data = [];
    if (pitlaneOpen1) {
      data?.push(pitlaneOpen1);
    }
    if (pitlaneOpen2) {
      data?.push(pitlaneOpen2);
    }
    if (pitlaneOpen3) {
      data?.push(pitlaneOpen3);
    }

    let durations = [];
    if (pitlaneClose1) {
      durations?.push(pitlaneClose1);
    }
    if (pitlaneClose2) {
      durations?.push(pitlaneClose2);
    }
    if (pitlaneClose3) {
      durations?.push(pitlaneClose3);
    }

    let refactoredData = [];

    const timer = setInterval(() => {
      data?.map((item, index) => {
        let deference1 = new Date(item?.unix).getTime() - new Date().getTime();
        let deference2 =
          new Date(durations[index]?.unix).getTime() - new Date().getTime();

        let duration =
          new Date(durations[index]?.unix).getTime() -
          new Date(item?.unix).getTime();

        if (deference1 <= 0 && deference2 <= 0) {
          item["show"] = false;
          item["duration"] = duration;
          item["pitlaneClose"] = durations[index]?.unix;

          upsert(item, refactoredData);
        } else {
          item["show"] = true;
          item["duration"] = duration;
          item["pitlaneClose"] = durations[index]?.unix;

          upsert(item, refactoredData);
        }

        console.log(deference1, deference2, deference1 >= 0, deference2 >= 0);
      });

      console.log("HI LOOP", refactoredData);

      let dataToSave = [];

      refactoredData?.map((i) => {
        if (i?.show === true) {
          dataToSave.push(i);
        }
      });

      setCountdowns(dataToSave);
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    pitlaneOpen1,
    pitlaneClose1,
    pitlaneOpen2,
    pitlaneClose2,
    pitlaneOpen3,
    pitlaneClose3,
  ]);

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  return (
    <div className="groupMode">
      <section className="topSection">
        <div className="left">
          <div>
            <input
              type="radio"
              id="html"
              name="fav_language"
              value="group"
              checked={mode === "group"}
              onChange={(e) => e.target.checked && setMode("group")}
            />
            {"  "}
            <label
              style={{ fontWeight: 500, fontSize: "1rem" }}
              htmlFor="group"
              checked={mode === "group"}
            >
              Group Mode
            </label>
            <br />
            <input
              style={{ marginTop: 10 }}
              type="radio"
              id="css"
              name="fav_language"
              value="openPitlane"
              checked={mode === "openPitlane"}
              onChange={(e) => e.target.checked && setMode("openPitlane")}
            />
            {"  "}
            <label
              style={{ fontWeight: 500, fontSize: "1rem", marginTop: -5 }}
              htmlFor="openPitlane"
              checked={mode === "openPitlane"}
            >
              Open Pitlane
            </label>
          </div>

          <div></div>
        </div>

        <div className="center" style={{ flexDirection: "column", gap: 40 }}>
          <img src={logo} alt="" style={{ width: "100%", height: "5vw" }} />
          <CurrentTime currentDate={currentDate} />
        </div>

        <div className="right">
          <div
            className="turns"
            style={{
              gap: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div className="turn">
                <p>Pitlane opens:</p>
                <input
                  style={{ padding: "5px 10px" }}
                  type="time"
                  required
                  value={pitlaneOpen1?.value}
                  onChange={async (e) => {
                    const data = {
                      turn: 1,
                      value: e.target.value,
                      unix: `${date} ${e.target.value}:00`,
                    };
                    setPitlaneOpen1(data);

                    await localStorage.setItem(
                      "pitlaneOpen1",
                      JSON.stringify(data)
                    );
                  }}
                />
              </div>
              <div className="turn">
                <p>Pitlane closes:</p>
                <input
                  style={{ padding: "5px 10px" }}
                  type="time"
                  min={pitlaneOpen1?.value}
                  required
                  value={pitlaneClose1?.value}
                  onChange={async (e) => {
                    const data = {
                      turn: 1,
                      value: e.target.value,
                      unix: `${date} ${e.target.value}:00`,
                    };
                    setPitlaneClose1(data);
                    await localStorage.setItem(
                      "pitlaneClose1",
                      JSON.stringify(data)
                    );
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div className="turn">
                <p>Pitlane opens:</p>
                <input
                  style={{ padding: "5px 10px" }}
                  type="time"
                  required
                  value={pitlaneOpen2?.value}
                  onChange={async (e) => {
                    const data = {
                      turn: 2,
                      value: e.target.value,
                      unix: `${date} ${e.target.value}:00`,
                    };
                    setPitlaneOpen2(data);

                    await localStorage.setItem(
                      "pitlaneOpen2",
                      JSON.stringify(data)
                    );
                  }}
                />
              </div>
              <div className="turn">
                <p>Pitlane closes:</p>
                <input
                  style={{ padding: "5px 10px" }}
                  type="time"
                  min={pitlaneOpen2?.value}
                  required
                  value={pitlaneClose2?.value}
                  onChange={async (e) => {
                    const data = {
                      turn: 2,
                      value: e.target.value,
                      unix: `${date} ${e.target.value}:00`,
                    };
                    setPitlaneClose2(data);
                    await localStorage.setItem(
                      "pitlaneClose2",
                      JSON.stringify(data)
                    );
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div className="turn">
                <p>Pitlane opens:</p>
                <input
                  style={{ padding: "5px 10px" }}
                  type="time"
                  required
                  value={pitlaneOpen3?.value}
                  onChange={async (e) => {
                    const data = {
                      turn: 3,
                      value: e.target.value,
                      unix: `${date} ${e.target.value}:00`,
                    };
                    setPitlaneOpen3(data);

                    await localStorage.setItem(
                      "pitlaneOpen3",
                      JSON.stringify(data)
                    );
                  }}
                />
              </div>
              <div className="turn">
                <p>Pitlane closes:</p>
                <input
                  style={{ padding: "5px 10px" }}
                  type="time"
                  min={pitlaneOpen3?.value}
                  required
                  value={pitlaneClose3?.value}
                  onChange={async (e) => {
                    const data = {
                      turn: 3,
                      value: e.target.value,
                      unix: `${date} ${e.target.value}:00`,
                    };
                    setPitlaneClose3(data);
                    await localStorage.setItem(
                      "pitlaneClose3",
                      JSON.stringify(data)
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bottomSection"
        style={{ backgroundColor: "#fff", gridTemplateColumns: "1fr" }}
      >
        {countdowns?.map((t, index) => {
          return (
            index <= 0 && (
              <CountdownTimer
                item={t}
                date={t?.unix}
                groupId="group1Name"
                turnName={t?.turn}
              />
            )
          );
        })}

        {countdowns?.length <= 0 && (
          <AddTurn
            msg={
              pitlaneClose1 || pitlaneClose2 || pitlaneClose3
                ? "Pitlane Over"
                : "Add Open Pitlane"
            }
          />
        )}
      </section>
    </div>
  );
}

const CountdownTimer = ({ item, date, duration }) => {
  const [days, hours, minutes, seconds] = useCountdown(date);
  const [days2, hours2, minutes2, seconds2] = useCountdown(item?.pitlaneClose);

  return (
    <section
      className={`countdownTimer colorChnage ${
        days + hours + minutes + seconds <= 0 ? "colorGreen" : ""
      }`}
    >
      <div
        className="groupName"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "2.5vw",
          padding: "10px 0",
        }}
      >
        <h2>
          {days + hours + minutes + seconds <= 0
            ? `PITLANE OPEN FOR`
            : "PITLANE OPEN IN"}
        </h2>
      </div>

      {days + hours + minutes + seconds <= 0 ? (
        <h1>
          {/* {`${item?.duration / 1000 / 60} MINUTES`} */}
          {hours2 === 0 ? "" : (hours2 < 10 ? "0" + hours2 : hours2) + ":"}
          {minutes2 < 10 ? "0" + minutes2 : minutes2}:
          {seconds2 < 10 ? "0" + seconds2 : seconds2}
        </h1>
      ) : (
        <h1>
          {hours === 0 ? "" : (hours < 10 ? "0" + hours : hours) + ":"}
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </h1>
      )}
    </section>
  );
};

const upsert = (element, array) => {
  const i = array.findIndex((_element) => _element.turn === element.turn);
  if (i > -1) {
    array[i] = element;
  } else {
    console.log("NO MATCH", i, array);
    array.push(element);
  }
};

const AddTurn = ({ msg }) => {
  return (
    <section className="countdownTimer">
      <h1>{msg || "Add Turn"}</h1>
    </section>
  );
};
